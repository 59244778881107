import { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { useParams } from "react-router-dom";
import { message } from "antd";

export const useHistory = () => {
  const [isLoading, setLoading] = useState(false);
  const [intercomId, setIntercomId] = useState(useParams);
  const [checkedIDs, setCheckedIDs] = useState<any[]>([]);
  const [hexes, setHexes] = useState([]);
  const [keys, setKeys] = useState([]);
  const [showFlats, setShowFlats] = useState<boolean>(false);
  const [flats, setFlats] = useState([]);
  const [selectedFlat, setSelectedFlat] = useState("");
  const [filteredValue, setFilteredValue] = useState<string>("");
  const [showNFCConfirm, setShowNFCConfirm] = useState(false);
  const [areAllChecked, setAreAllChecked] = useState<boolean>(false);
  const [relatedIntercoms, setRelatedIntercoms] = useState(null);
  const [relatedFlats, setRelatedFlats] = useState([]);
  const { authAxios, authAxios2 } = useContext(FetchContext);

  useEffect(() => {
    getKeyLogs();
    getRelatedIntercoms();
  }, [authAxios2]);

  useEffect(() => {
    if(showFlats) {
      getFlats();
    }
  }, [showFlats]);

  const getRelatedIntercoms = async () => {
    try {
      const response = await authAxios.get(`/admin/intercoms/${intercomId.id}/relations`);
      if(response) {
        //console.log('GET INTERCOMS RESPONSE ==>', response.data.data);
        setRelatedIntercoms(response.data.data.relations);
      }
    } catch (e) {
      console.log('GET INTERCOMS ERROR ==>', e);    
    }
  };

  const getRelatedFlats = async (flatId) => {
    try {
      const response = await authAxios.get(`/admin/intercoms/${intercomId.id}/relatedFlats/${flatId}`);
      if(response) {
        console.log('Related Flats ==>', response);
        setRelatedFlats(response.data.data.relatedFlats);
      }
    } catch (e) {
      console.log('Related Flats ERROR ==>', e);
      
    }
  };

  const getFlats = async () => {
    try {
        const response = await authAxios.get(`/admin/intercoms/${intercomId.id}`);
        if(response) {
            //console.log('GET FLATS ==>', response);
            setFlats(response.data.data.flats);
        }
    } catch (e) {
        console.log('GET FLATS ERROR ==>', e);
        message.error(
          e.response.data.message != null
            ? e.response.data.message
            : "Flats could not be shown. Please try again later."
        ); 
        
    }
  };

  const assignKeys = async () => {
    let data = [
      {
        flat_id: selectedFlat.toString(),
        hexes: hexes,
        device_id: intercomId.id
      }
    ];

    if(relatedIntercoms) {
      for(let relation of relatedIntercoms.relatedIntercomIds) {
        for(let flat of relatedFlats) {
          if(relatedIntercoms.baseIntercom === intercomId.id) {
            if(flat.intercomId === relation) {
              data.push({
                flat_id: flat.flatId,
                hexes: hexes,
                device_id: relation
              });
            }
          } else {
            data.push({
              flat_id: flat.flatId,
              hexes: hexes,
              device_id: relatedIntercoms.baseIntercom
            });
          }
        }
      }
    }

    for(let item of data) {
      try {
        const response = await authAxios2.post(`/admin/keys/create`, item);
        if(response) {
          //console.log('Add Key ==>', response);
          message.success(
            response.data.message != null
              ? response.data.message
              : "Key has been successfully added"
          );
        }
      } catch (err) {
        console.log('Add Key ERROR ==>', err);
        message.error(
          err.response.data.message != null
            ? err.response.data.message
            : "Key could not be added!"
        );
      }
    }

    setShowFlats(false);
    getKeyLogs();
  };

  const handleAddKeysOnClick = () => {
    setShowFlats(true);
  };

  const handleAddKeysCancel = () => {
    setShowFlats(false);
  };

  const getKeyLogs = async () => {
    setLoading(true);
    try {
        const response = await authAxios2.get(`/admin/keys/logs/${intercomId.id}`);
        if(response) { 
          const newArray = [];
          for(let data of response.data) {
            let newData = {
              createdAt: data.createdAt,
              flat_id: data.flat_id,
              id: data.id,
              intercom_id: data.intercom_id,
              code: data.key,
              updatedAt: data.updatedAt,
              event: data.event,
              address: data.address
            };
            newArray.push(newData);
          };
          setKeys(newArray);
          setLoading(false);
        }
    } catch (e) {
        console.log('GET KEYS ERROR ==>', e); 
        message.error(
          e.response.data.message != null
            ? e.response.data.message
            : "Keys could not be shown. Please refresh."
        );  
    } 
  };

  const changeCheckedValue = (row, id) => { 
    if (checkedIDs.includes(id)) {
      setCheckedIDs(checkedIDs.filter((it) => it != id));
      setHexes(hexes.filter((it) => it != row.code));
    } else {
      setCheckedIDs([...checkedIDs, id]);
      setHexes([...hexes, row.code]);
    }
  };

  const changeSelectAll = () => {
    if (checkedIDs.length !== 0) {
      setCheckedIDs([]);
      setHexes([]);
      setAreAllChecked(false);
    } else {
      setAreAllChecked(true);
      setCheckedIDs(keys.map((it) => it.id));
      setHexes(keys.map((it) => it.code))
    }
  };

  const changeCheckedFlats = (row, id) => {
   if (selectedFlat === id) {
      setSelectedFlat("");
    } else {
      setSelectedFlat(id);
      getRelatedFlats(id);
    }
  };

  const handleDeleteNFCCode = async () => {
    try {
      const response = await authAxios2.post(`/admin/keys/delete`, {
        keys: checkedIDs
      });
      if(response) {
        //console.log('DELETE KEY ==>', response);
        getKeyLogs();
        setCheckedIDs([]);
        setHexes([]);
        message.success(
          response?.data?.message != null
            ? response.data.message
            : "Key has been successfully deleted!"
        ); 
      }
    } catch (e) {
      console.log('DELETE KEY ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "Keys could not be deleted!"
      );  
    }
  };

  const handleCancelNFCCodeDelete = () => {
    setShowNFCConfirm(false);
  };

  return {
    changeCheckedValue,
    checkedIDs,
    keys,
    isLoading,
    showFlats,
    handleAddKeysOnClick,
    handleAddKeysCancel,
    assignKeys,
    flats,
    selectedFlat,
    changeCheckedFlats,
    setFilteredValue,
    filteredValue,
    handleDeleteNFCCode,
    handleCancelNFCCodeDelete,
    areAllChecked,
    changeSelectAll
  };
};
