import { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { DatePickerProps, message, RadioChangeEvent } from "antd";
import { urbanAddresses } from "../../util/addresses";
import dayjs from "dayjs";

export const ALL_FLATS = "All Flats";
export const ID = "Id";
export const useEditIntercom = () => {
  const {t} = useTranslation();
  const [flats, setFlats] = useState<any[]>([]);
  const [deviceID, setDeviceID] = useState(useParams);
  const [device, setDevice] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAddKeys, setShowAddKeys] = useState(false);
  const [showAddFlats, setShowAddFlats] = useState(false);
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [isDetailsModalVisible, setDetailsModalVisibility] = useState<boolean>(
    false
  );
  const [selectedFlat, setSelectedFlat] = useState([]);
  const [addresses, setAddresses] = useState<any[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [selectedComType, setSelectedComType] = useState<string>("");
  const [paymentType, setPaymentType] = useState<string>("");
  const [secondDoor, setSecondDoor] = useState<boolean>(false);
  const [parking, setParking] = useState<boolean>(false);
  const [parkingInfo, setParkingInfo] = useState<any>({});
  const [debtInfo, setDebtInfo] = useState<any>({});
  const [areAllChecked, setAreAllChecked] = useState<boolean>(false);
  const [flatOffsetNum, setFlatOffsetNum] = useState<number>(0);
  const [exportData, setExportData] = useState<any[]>([]);
  const [isEditIntercomLoading, setEditIntercomLoading] = useState<boolean>(
    false
  );
  const [isSortedByBalance, setIsSortedByBalance] = useState<boolean>(false);
  const [isNotificationSending, setIsNotificationSending] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(
    false
  );
  const [pushNotificationModal, setPushNotificationModal] = useState<boolean>(
    false
  );
  const [pushNotificationError, setPushNotificationError] = useState<boolean>(
    false
  );
  const [checkedIDs, setCheckedIDs] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [flatsData, setFlatsData] = useState<any[]>(flats);
  const [intercomsData, setIntercomsData] = useState<any[]>([]);
  const [selectedService, setSelectedServices] = useState(ALL_FLATS);
  const [selectedDate, setDate] = useState({
    date: selectedService !== ALL_FLATS && (urbanAddresses[deviceID.id] && !urbanAddresses[deviceID.id].includes('Tsitsernakaberd')) ? dayjs().set('month', dayjs().month() - 1) : dayjs(),
    dateString: selectedService !== ALL_FLATS && (urbanAddresses[deviceID.id] && !urbanAddresses[deviceID.id].includes('Tsitsernakaberd')) ? dayjs().set('month', dayjs().month() - 1).format("YYYY-MM") : dayjs().format("YYYY-MM"),
  });
  const [serviceFlatValues, setServiceFlatValues] = useState({});
  const [fixedPriceModal, setFixedPriceModal] = useState<boolean>(false);
  const [paymentsFile, setPaymentsFile] = useState(null);
  const [paymentValue, setPaymentValue] = useState(null);
  const [unitValueType, setUnitValueType] = useState<boolean>(false);
  const [days, setDays] = useState(null);
  const [showConnectIntercoms, setShowConnectIntercoms] = useState<boolean>(false);
  const [usersStatus, setUsersStatus] = useState({});
  const [flatsStatus, setFlatsStatus] = useState({});
  const [selectedAccount, setSelectedAccount] = useState('');
  const [vposAccounts, setVposAccounts] = useState<any[]>([]);
  const { authAxios, authAxios2 } = useContext(FetchContext);
  const [filteredValue, setFilteredValue] = useState<string>("");
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [flatCharged, setFlatCharged] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getActiveUsers = async () => {
    try {
      const response = await authAxios.get(`/admin/intercoms/${deviceID.id}/activeUsers`);
      if(response) {
        setUsersStatus(response.data.data.activeUsers);
      }
    } catch (e) {
      console.log('Get Active Users ERROR ==>', e);  
    }
  };

  const exportPaymentData = () => {
    const url = window.URL.createObjectURL(new Blob([paymentsFile]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${t("payments")}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  const getPaymentsFile = async () => {
    setIsLoading(true);
    //const today = new Date();
    //const month = String(today.getMonth() + 1).padStart(2, '0');
    const selected_date = new Date(selectedDate.dateString);
    const year = selected_date.getFullYear();

    let startMonth = `${year}-01`;
    let endMonth = selectedDate.dateString;

    try {
      const response = await authAxios.get(`/admin/payments/${deviceID.id}?startMonth=${startMonth}&endMonth=${endMonth}`, {
        responseType: 'blob'
      });
      if(response) {
        //console.log('GET PAYMENTS FILE RESPONSE ==>', response.data);
        setPaymentsFile(response.data);
        setIsLoading(false);
      }
    } catch (e) {
      console.log('GET PAYMENTS FILE ERROR ==>', e);
      setIsLoading(false);
    }
  };

  const getParkingInfo = async (addressId) => { 
    try {
      const response = await authAxios2.get(`/parking/get/${addressId}`);
      if(response) {
        //console.log('GET PARKING INFO ==>', response);
        setParking(true);
        setParkingInfo(response.data.parking);
      }
    } catch (e) {
      console.log('PARKING INFO ERROR ==>', e);
      setParking(false);  
    }
  };

  const getDebtInfo = async () => {
    try {
      const response = await authAxios.get(`/admin/intercoms/${deviceID.id}/fix-amount`);
      if(response) {
        //console.log('GET DEBT INFO ==>', response.data.data);
        setDebtInfo(response.data.data);
      }
    } catch (e) {
      console.log('DEBT INFO ERROR ==>', e);
      
    }
  };


  useEffect(() => {
    //getActiveUsers();
    getDevices();
    loadServices();
  }, [authAxios]);

  useEffect(() => {
    if(selectedService !== ALL_FLATS && selectedService !== ID && (urbanAddresses[deviceID.id] && !urbanAddresses[deviceID.id].includes('Tsitsernakaberd'))) {
      let newDate = {
        date: dayjs().set('month', dayjs().month() - 1),
        dateString: dayjs().set('month', dayjs().month() - 1).format("YYYY-MM"),
      };
      setDate(newDate);
      onServiceChange(selectedService, newDate);
    } else {
      let currentDate = {
        date: dayjs(),
        dateString: dayjs().format("YYYY-MM"),
      };
      setDate(currentDate);
      onServiceChange(selectedService, currentDate);
    }
  }, [selectedService]);

  useEffect(() => {
    getPaymentsFile();
  }, [selectedService, selectedDate.dateString]);

  useEffect(() => {
    if (isSortedByBalance) {
      const newData = flats.sort((f, s) => f.balance - s.balance);
      setFlatsData([...newData]);
    } else {
      const newData = flats.sort((f, s) => f.flatNumber - s.flatNumber);
      setFlatsData([...newData]);
    }
  }, [isSortedByBalance, flats]);
  const getDevices = async () => {
    const data = await authAxios.get(`/admin/intercoms/${deviceID.id}`);
    const deviceDetails = {
      id: data.data.data.id,
      name: data.data.data.name,
      serialNumber: data.data.data.serialNumber,
      isOnline: data.data.data.isOnline,
      isActivated: data.data.data.isActivated,
      address: data.data.data.address,
      paymentType: data.data.data.paymentType,
      hasSecondDoor: data.data.data.hasSecondDoor,
      lockName: '',
      secondDoorName: '',
      vposAccountId: data.data.data.vposAccountId
    };
    setPaymentType(data.data.data.paymentType);
    setSecondDoor(data.data.data.hasSecondDoor ? data.data.data.hasSecondDoor : false);
    setDevice([deviceDetails]);
    setFlatOffsetNum(data.data.data.flatOffset);
    setFlats(data.data.data.flats);
    setUsersStatus({activeUsers: data.data.data.activeUsers, disabledUsers: data.data.data.disabledUsers});
    setFlatsStatus({activeFlats: data.data.data.activeFlats, disabledFlats: data.data.data.disabledFlats});
    setSelectedAccount(data.data.data.vposAccountId);
    let excelValue = [];
    setExportData(
      data.data.data.flats.map((flat) => {
        // flat.userCodes.map((user) => {
          excelValue.push({
            flatNumber: flat.flatNumber,
            code: flat.userCodes[0]?.code,
            address: data.data.data.address
          });
        // });
        return excelValue;
      })
    );

    getParkingInfo(data.data.data.address.id);
    getDebtInfo()
  };
  const deleteIntercom = async () => {
    await authAxios
      .delete(`/admin/intercoms/${deviceID.id}`)
      .then((response) => {
        if (response.status === 200) {
          message.success("Intercom has been successfully deleted!");
        }
      })
      .catch((err) => {
        message.error("There was a problem while deleting intercom!");
      });
  };

  const handleConfirm = () => {
    deleteIntercom()
      .then(() => {
        window.location.href = "/intercoms";
      })
      .catch((err) => {
        message.error("There was a problem while deleting intercom!");
      });
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  const handleFlatDeleteCancel = () => {
    setShowConfirm(false);
  };

  const handleAddNotificationOnClick = (value: any) => {
    if(value === "push") {
      setPushNotificationModal(true);
    } else {
      setShowNotificationModal(true);
    }
  };

  const handlePushNotificationCancel = () => {
    setCheckedIDs([]);
    setAreAllChecked(false);
    setPushNotificationModal(false);
  };

  const handleAddNotificationCancel = () => {
    setShowNotificationModal(false);
  };

  const pushNotifications = async () => { 
    const serviceIds = [];
    
    for(let service of services) {
      serviceIds.push(service.id);
    }
     
    try {
      const response = await authAxios.put(`/admin/services/charge/notification/date/${selectedDate.dateString}`,
        {
          serviceIds: serviceIds,
          flatIds: checkedIDs
        }
      );
      if(response) {
        if(!response.data.data.failedFlatNumber.length) {
          setPushNotificationModal(false);
          setCheckedIDs([]);
          setAreAllChecked(false);            
          message.success(
            response.data.message !== null &&
              response.data.message === "notification.sent"
              ? response.data.message
              : "Notification has been successfully sent"
          );
        } else {
          const replacements = {
            "[flats]": `${response.data.data.failedFlatNumber.toString()}`,
          };
          let errorMessage = t('PushPopupErrorText');
          Object.keys(replacements).forEach((key) => {
            errorMessage = errorMessage.replace(key, replacements[key]);
          });

          message.error(errorMessage);
        }
      }
    } catch (e) {
      console.log('Push Notification ERROR ==>', e);
      message.error(
            e.response.data.message != null
              ? e.response.data.message
              : "Notification could not be send!"
          );
    }
  };

  const generateCodes = async () => {
    await authAxios
      .post(`/admin/intercoms/${deviceID.id}/provision`)
      .then((response) => {
        if (response.status === 200) {
          message.success("Codes have been successfully generated!");
        }
      })
      .catch((err) => {
        message.error("Codes couldn't be generated. Please try again later!");
      });
  };

  const changeCheckedValue = (row, id) => {
    if (checkedIDs.includes(id)) {
      setCheckedIDs(checkedIDs.filter((it) => it != id));
      setSelectedFlat(id);
    } else {
      setCheckedIDs([...checkedIDs, id]);
      setFlatCharged({...flatCharged, [`${id}`]: row.charged})
    }
  };

  const changeSelectAll = () => {
    if (checkedIDs.length !== 0) {
      setCheckedIDs([]);
      //setDevice([]);
      setAreAllChecked(false);
    } else {
      setAreAllChecked(true);
      setCheckedIDs(flats.map((it) => it.id));
      serviceFlatValues[selectedService].map((flat) => {
        flatCharged[`${flat.flatId}`] = flat.charged
      });
    }
  };

  const generateFlatCodes = async () => {
    const flatNumber = selectedFlat["flatNumber"];
    await authAxios
      .post(`/admin/intercoms/${deviceID.id}/generateCode/${flatNumber}`)
      .then((response) => {
        if (response.status === 200) {
          message.success("Codes have been successfully generated!");
        }
      })
      .catch((err) => {
        message.error("Codes couldn't be generated. Please try again later!");
      });
  };

  const addNotifications = async (values: any) => {
    const addNotification = async () => {
      setIsNotificationSending(true);
      await authAxios
        .post(`/notifications/flats`, {
          flatIds: checkedIDs,
          title: values.title,
          body: values.body,
        })
        .then((response) => {
          if (response) {
            setShowNotificationModal(false);
            message.success(
              response.data.message != null &&
                response.data.message === "notification.sent"
                ? "Notification has been successfully added"
                : response.data.message
            );
            setIsNotificationSending(false);
          }
          setAreAllChecked(false);
          setCheckedIDs([]);
        })
        .catch((err) => {
          message.error(
            err.response.data.message != null
              ? err.response.data.message
              : "Notification could not be added!"
          );
          setIsNotificationSending(false);
        });
    };
    addNotification();
  };

  const addKeys = async (values: any) => {
    setShowAddKeys(true);
    const addKey = async () => {
      await authAxios
        .post(`/admin/intercoms/${deviceID.id}/createKey`, {
          keys: [values.hex],
        })
        .then((response) => {
          if (response) {
            setShowAddKeys(false);
            message.success(
              response.data.message != null
                ? response.data.message
                : "Key has been successfully added"
            );
          }
        })
        .catch((err) => {
          message.error(
            err.response.data.message != null
              ? err.response.data.message
              : "Key could not be added!"
          );
        });
    };
    addKey();
  };

  const addFlats = async (values: any) => {
    const addFlat = async () => {
      await authAxios
        .post(`/flats/addFlat`, {
          deviceId: deviceID.id,
          flats_config: {
            redirection: "direct",
            sound_vol: 101,
            blocked: false,
            alias: null,
            num: values.num,
            analog_settings: { alias: 0 },
          },
        })
        .then((response) => {
          if (response) {
            setShowAddFlats(false);
            setFlats(response.data.data);
            message.success(
              response.data.message != null
                ? response.data.message
                : "Flat has been successfully added"
            );
          }
        })
        .catch((err) => {
          message.error(
            err.response.data.message != null
              ? err.response.data.message
              : "Flat could not be added!"
          );
        });
    };
    addFlat();
  };

  const handleSecondDoor = async (value) => {
    try {
      const response = await authAxios.patch(
        `/admin/intercoms/${deviceID.id}/second-door`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        //console.log('SECOND DOOR STATUS ==>', response);
        return response;
      }
    } catch (error) {
      //console.log('SECOND DOOR STATUS ERROR ==>', error);
      message.error(
        "There was a problem with address update. Please try again later!"
      );
      setModalVisibility(false);
      setSelectedAddress("");
    }
  };

  const handleParking = async (value, name) => { 
    if(value === true) {
      try {
        const response = await authAxios2.post(`/parking/create`, {
          name: name, 
          address_id: device[0].address.id,
          intercom_id: deviceID.id
        });
        if(response) {
          //console.log('PARKING CREATED ==>', response);   
        }
      } catch (e) {
        console.log('PARKING ERROR ==>', e);
        message.error(
          "There was a problem with address update. Please try again later!"
        );
      }
    } else {
      if(Object.values(parkingInfo).length) {
        try {
          const response = await authAxios2.delete(`parking/delete/${parkingInfo.id}`);
          if(response) {
            //console.log('PARKING DELETED ==>', response);          
          }
        } catch (e) {
          console.log('PARKING ERROR ==>', e);
          message.error(
            "There was a problem with address update. Please try again later!"
          );
        }
      }
    }
  };

  const handleDebt = async (fix_amount, min_amount) => {
    try {
      const response = await authAxios.post(`/admin/intercoms/${deviceID.id}/fix-amount`, {
        fixAmount: Number(fix_amount),
        minPayment: Number(min_amount)
      })
    } catch (e) {
      console.log('DEBT ERROR ==>', e);
      message.error(
        "There was a problem with address update. Please try again later!"
      );
    }
  };

  const handleDeviceEdit = async (editData) => {
    setEditIntercomLoading(true);
    const newAddress = addresses.find(
      (address) => address.description === selectedAddress
    );
    try {
      await authAxios.put(`/admin/intercoms/${deviceID.id}`, {
        address: editData.address,
        google_id: newAddress ? newAddress.place_id : "",
        ext_com: editData.ext_com
          ? parseInt(editData.ext_com)
          : parseInt(selectedComType),
        flat_offset: editData.flat_offset
          ? editData.flat_offset
          : flatOffsetNum,
        payment_type: paymentType,
        vposAccountId: editData.vposAccount
        //lockName: editData.lock_name,
        //secondDoor_name: editData.second_lock_name
      });
      const secondDoorResponse = await handleSecondDoor(secondDoor);
      const parkingResponse = await handleParking(parking, editData.parking_name);
      if(debtInfo.fixAmount !== Number(editData.condominium_debt) || debtInfo.minPayment !== Number(editData.repayment_amount)) {
        await handleDebt(editData.condominium_debt, editData.repayment_amount);
      }

      setModalVisibility(false);
      setAddresses([]);
      setSelectedAddress("");
      message.success("Address Has Been Updated Successfully");
    } catch (error) {
      message.error(
        "There was a problem with address update. Please try again later!"
      );
      setModalVisibility(false);
      setSelectedAddress("");
    }
    setEditIntercomLoading(false);
    getDevices();
  };

  const handleOnSelect = (selected: string) => {
    setSelectedAddress(selected);
  };

  const handleCancelEdit = () => {
    setSelectedAddress("");
    setSelectedAccount("");
    setAddresses([]);
    setModalVisibility(false);
  };

  const onAccountChange = (id: string) => { 
    setSelectedAccount(id);
  };

  const getVPOSAccounts = async () => {
    try {
      const response = await authAxios.get(`/admin/vpos-account/all`);
      if(response) {
        setVposAccounts(response.data.data.map((el) => ({ ...el, label: el.name, value: el.id })));
      }
    } catch (e) {
      console.log('GET VPOS ACCOUNTS ERROR ==>', e); 
    }
  };

  const showModal = () => {
    setAddresses([]);
    setModalVisibility(true);
    getVPOSAccounts();
  };

  const handleDetailsOnClick = (flat: any) => {
    setSelectedFlat(flat);
    sessionStorage.setItem('address_id', device[0].address.id);
    sessionStorage.setItem('address', JSON.stringify(device[0].address));
    window.location.href = `/intercoms/${deviceID.id}/flat/${flat.id}`;
  };

  const handleAddFlatsOnClick = (flat: any) => {
    setShowAddFlats(true);
  };

  const handleAddFlatsCancel = (flat: any) => {
    setShowAddFlats(false);
  };
  const handleFlatDelete = (flat: any) => {
    const deleteFlat = async () => {
      await authAxios
        .delete(`/flats/delete/${deviceID.id}/${flat.flatNumber}`)
        .then((response) => {
          if (response) {
            setFlats(response.data.data);
            message.success("Flat has been successfully deleted");
          }
        })
        .catch((err) => {
          message.error(
            "There was a problem with flat deletion. Please try again later!"
          );
        });
    };
    deleteFlat();
  };

  const handleChangeComType = (selected: string) => {
    setSelectedComType(selected);
  };
  const paymentTypeChange = (e: RadioChangeEvent) => {
    setPaymentType(e.target.value);
  };
  const secondDoorChange = (e: RadioChangeEvent) => {
    setSecondDoor(e.target.value);
  };
  const parkingChange = (e: RadioChangeEvent) => {
    setParking(e.target.value);
  };
  const handleAddKeysOnClick = (flat: any) => {
    setShowAddKeys(true);
  };
  const handleAddKeysCancel = (flat: any) => {
    setShowAddKeys(false);
  };

  const handleFlatActivationOnClick = (flat: any) => {
    const updateActive = async () => {
      await authAxios
        .post(`/flats/active/${deviceID.id}`, {
          isActive: !flat.active,
          flatNumber: flat.flatNumber,
        })
        .then((response) => {
          if (response) {
            setFlats(response.data.data);
            message.success("Flat has been successfully updated");
          }
        })
        .catch((err) => {
          message.error(
            "There was a problem flat update. Please try again later!"
          );
        });
    };
    updateActive();
  };

  const handleDetailsOk = () => {
    setDetailsModalVisibility(false);
  };

  const handleDetailsCancel = () => {
    setDetailsModalVisibility(false);
  };

  const getAutocompleteData = (keyword: string) => {
    const url =
      "https://corsproxy.io/?" +
      encodeURIComponent(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${keyword}&key=AIzaSyBwdzw5azt7UHEUzGxkZ56obebQTfRWZ8g&types=address&region=AM&country=armenia`
      );

    return fetch(url)
      .then(async (res) => {
        if (res.status >= 400) {
          throw await res.json();
        }
        return res.json();
      })
      .then((data) => {
        setAddresses(data.predictions || []);
      })
      .catch((error) => console.log(error));
  };

  const loadServices = async () => {
    setEditIntercomLoading(true);
    const { data } = await authAxios.get(
      `/admin/services/intercoms/${deviceID.id}`
    );
    setServices(
      data.data.map((el) => ({ ...el, label: el.name, value: el.id }))
    );
    setEditIntercomLoading(false);
  };

  const onServiceChange = async (serviceId:string, date)=>{ 
    const existingService = services.filter((service) => service.id === serviceId);

    setCheckedIDs([]);
    setDays(null);

    if(serviceId !== "All Flats" && serviceId !== "Id" && existingService.length && selectedService !== "All Flats" && selectedService !== "Id") {
      const { data } = await authAxios.get(
        `/admin/services/${serviceId}/intercoms/${deviceID.id}/date/${date.dateString}`
      );
      setServiceFlatValues((prevState) => ({
        ...prevState,
        [serviceId]: data.data,
      }));
    }

    if(existingService.length) {
     setSelectedServices(serviceId);
     localStorage.setItem('selectedService', JSON.stringify(serviceId));
    } else {
      setSelectedServices(serviceId);
      localStorage.removeItem('selectedService');
    }
  };

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setDate({ date, dateString });
    onServiceChange(selectedService, { date, dateString });
  };

  const addServiceValues = async (datas)=>{
    let body = {
      flatId: datas.key,
      unitValue: datas.serviceValue,
      isSave: unitValueType,
    }

    if(datas.days !== null) {
      body["days"] = datas.days;
    }
    
    const {data:xxx} = await authAxios.post(`/admin/services/${selectedService}/date/${selectedDate.dateString}`, body);

    onServiceChange(selectedService, selectedDate);
    setFixedPriceModal(false);
  };

  const changeTotalAmount = async (datas, total) => {
    const { data: xxx } = await authAxios.patch(
      `/admin/services/${selectedService}/flats/${datas.key}/total`,
      {
        date: selectedDate.dateString,
        totalAmount: Math.ceil(total),
      }
    );
  };

  const handleCharge = async (flatIds) => {
    let ids = flatIds.filter((id) => flatCharged[id] === false || !flatCharged[id]); 
    
    try {
      const response = await authAxios.post(`/admin/services/charge/${selectedService}/date/${selectedDate.dateString}`, ids);
      if(response) {
        //console.log('CHARGE RESPONSE ==>', response);
        setCheckedIDs([]);
        onServiceChange(selectedService, selectedDate);
        message.success("Charged Successfully");
      }
    } catch (e) {
      console.log('CHARGE ERROR ==>', e);
      message.error("Charge Failed. Please try again later!");
    }
  };

  const handleRefund = async (flatId) => {
    try {
      const response = await authAxios.post(`/admin/services/refund/${selectedService}/date/${selectedDate.dateString}/flat/${flatId}`);
      if(response) {
        //console.log('REFUND RESPONSE ==>', response);
        setCheckedIDs([]);
        onServiceChange(selectedService, selectedDate);
        message.success("Refund Completed Successfully");
      }
    } catch (e) {
      console.log('REFUND ERROR ==>', e);
      message.error("Refund Failed. Please try again later!");
    }
  };

  const handlePaymentInputEnter = async (e: any) => {
    let list = [];

    for(let id of checkedIDs) {
      list.push({
        flatId: id,
        amount: Number(e.target.value)
      })
    }
    
    try {
      const response = await authAxios.put(`/admin/flats/balance`, {
        flatBalanceList: list
      });
      if(response) {
        message.success("Payment Completed Successfully");
        setCheckedIDs([]);
        setPaymentValue(null);
        getDevices();
      }
    } catch (e) {
      message.error("Payment Failed. Please try again later!");
    }
  };

  const handleFixedPriceCancel = () => {
    onServiceChange(selectedService, selectedDate);
    setFixedPriceModal(false);
  };

  const unitValueTypeChange = (e: RadioChangeEvent) => {
    setUnitValueType(e.target.value);
  };

   const daysNumberChange = (e) => {
    setDays(e.target.value);
  };

  const getIntercomsForConnect = async () => {
    try {
      const response = await authAxios.get(`/admin/intercoms/${deviceID.id}/relations`);
      if(response) {
        //console.log('GET INTERCOMS RESPONSE ==>', response.data.data);
        setIntercomsData(response.data.data.intercomList);
        if(response.data.data.intercomList.length) {
          for(let intercom of response.data.data.intercomList) {
            if(intercom.related === true) {
              setCheckedIDs([...checkedIDs, intercom.id]);
            }
          }
        }
      }
    } catch (e) {
      console.log('GET INTERCOMS ERROR ==>', e);    
    }
  };

  const handleAddIntercomOnClick = () => {
    setShowConnectIntercoms(true);
    getIntercomsForConnect();
  };

  const handleAddIntercomsCancel = () => {
    setShowConnectIntercoms(false);
  };

  const connectIntercoms = async (ids) => { 
    try {
      const response = await authAxios.post(`/admin/intercoms/relations`, {
        baseIntercomId: deviceID.id, 
        relatedIntercomIds: ids
      });
      if(response) {
        //console.log('CONNECT INTERCOMS RESPONSE ==>', response.data.data);
        setShowConnectIntercoms(false);
        message.success("Relation successfully created!");
      }
    } catch (e) {
      console.log('CONNECT INTERCOMS ERROR ==>', e); 
      message.error("Could not add relation. Please try again later!");   
    }
  };

  const deleteIntercomRelation = async (id) => {
    try {
      const response = await authAxios.delete(`/admin/intercoms/${deviceID.id}/relations/${id}`);
      if(response) {
        //console.log('DELETE INTERCOMS RESPONSE ==>', response);
        message.success("Relation successfully deleted!");
        //setShowConnectIntercoms(false);
      }
    } catch (e) {
      console.log('DELETE INTERCOMS ERROR ==>', e);  
      message.error("Delete Failed. Please try again later!");
    }
  };

  const toggleIntercomConnect = (row, id) => {
    if (checkedIDs.includes(id)) {
      setCheckedIDs(checkedIDs.filter((it) => it != id));
      deleteIntercomRelation(id);
    } else {
      setCheckedIDs([...checkedIDs, id]);
    }
  };

  const onFlatColorChange = async (colorCode, flat) => {
    setSelectedColor(colorCode);
    try {
      const response = await authAxios.post(`/admin/intercoms/${deviceID.id}/color`, {
        color: colorCode,
        flatNumbers: [flat]
      });
      if(response) {
        getDevices();
        message.success("Flat Color successfully changed!");
      }
    } catch (e) {
      console.log('Color Change Error ==>', e);
      message.error("Color Changed Failed. Please try again later!");
    }
  };

  const editExportIdValue = async (data) => {
    try {
      const response = await authAxios.put(`/admin/flats/${data.key}/export-id`, {
        flatId: `${data.key}`,
        exportId: `${data.exportId}`
      });
      if(response) {
        getDevices();
        message.success("ID successfully changed!");
      }
    } catch (e) {
      console.log('Edit Export Id Error ==>', e);
      message.error("Editing the ID failed. Please try again later!");
    }
  };

  return {
    flats,
    handleConfirm,
    handleCancel,
    generateCodes,
    generateFlatCodes,
    addKeys,
    device,
    handleDeviceEdit,
    handleOnSelect,
    handleCancelEdit,
    showModal,
    getAutocompleteData,
    addresses,
    isModalVisible,
    handleDetailsOnClick,
    handleFlatActivationOnClick,
    handleDetailsOk,
    handleDetailsCancel,
    isDetailsModalVisible,
    selectedFlat,
    selectedAddress,
    handleAddKeysCancel,
    handleAddKeysOnClick,
    showAddKeys,
    handleFlatDelete,
    handleFlatDeleteCancel,
    handleAddFlatsOnClick,
    showAddFlats,
    handleAddFlatsCancel,
    addFlats,
    handleChangeComType,
    selectedComType,
    flatOffsetNum,
    exportData,
    paymentTypeChange,
    secondDoorChange,
    paymentType,
    secondDoor,
    isEditIntercomLoading,
    services,
    selectedService,
    setSelectedServices,
    onServiceChange,
    onDateChange,
    selectedDate,
    addServiceValues,
    serviceFlatValues,
    checkedIDs,
    setCheckedIDs,
    areAllChecked,
    changeCheckedValue,
    setSelectedFlat,
    changeSelectAll,
    isNotificationSending,
    showNotificationModal,
    handleAddNotificationOnClick,
    handleAddNotificationCancel,
    addNotifications,
    isSortedByBalance,
    setIsSortedByBalance,
    flatsData,
    changeTotalAmount,
    handleCharge,
    handleRefund,
    getPaymentsFile,
    paymentsFile,
    handlePaymentInputEnter,
    paymentValue,
    setPaymentValue,
    fixedPriceModal,
    handleFixedPriceCancel,
    unitValueType,
    unitValueTypeChange,
    days,
    daysNumberChange,
    setFixedPriceModal,
    handleAddIntercomOnClick,
    showConnectIntercoms,
    handleAddIntercomsCancel,
    connectIntercoms,
    intercomsData,
    toggleIntercomConnect,
    deviceID,
    usersStatus,
    flatsStatus,
    parkingChange,
    parking,
    parkingInfo,
    pushNotificationModal,
    handlePushNotificationCancel,
    pushNotifications,
    vposAccounts,
    selectedAccount,
    onAccountChange,
    exportPaymentData,
    pushNotificationError,
    setFilteredValue,
    filteredValue,
    setIntercomsData,
    getIntercomsForConnect,
    debtInfo,
    selectedColor,
    onFlatColorChange,
    flatCharged,
    isLoading,
    editExportIdValue
  };
};
